<template>
  <div>
    <v-row>
      <nuxt-link
        :to="'https://www.swissmadesoftware.org/en/companies/tayo-sa/home.html'"
        target="_blank"
      >
        <nuxt-img
          class="cursor-pointer"
          src="/img/labels/swiss_made_label_software.png"
          :alt="$t('alt.swiss_made_label')"
          height="35"
          width="auto"
          placeholder
        />
      </nuxt-link>
    </v-row>
    <v-row class="mt-2">
      <nuxt-link
        :to="'https://www.swissmadesoftware.org/en/companies/tayo-sa/home.html'"
        target="_blank"
      >
        <nuxt-img
          class="cursor-pointer"
          src="/img/labels/swiss_made_label_digital_services.png"
          :alt="$t('alt.swiss_made_label')"
          height="38"
          width="auto"
          placeholder
        />
      </nuxt-link>
    </v-row>
  </div>
</template>

<style lang="scss">
</style>
