<template>
  <v-btn
    :to="'/' + $i18n.locale + '/tayo-mag'"
    flat
    large
    rounded="pill"
    class="tayo-secondary-btn"
    :aria-label="$t('button.community')"
    :append-icon="mdiArrowRight"
  >
    {{ $t('button.community') }}
  </v-btn>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'
export default {
  data: () => ({
    mdiArrowRight
  })
}
</script>
<style>

</style>
