<template>
  <div>
    <v-layout v-if="displayExternalPage">
      <tayo-immomig />
    </v-layout>
    <v-layout v-else>
      <TayoHeader />
      <v-main>
        <v-layout wrap class="d-flex flex-column">
          <v-alert
            v-model="showCookieAlert"
            position="fixed"
            elevation="1"
            type="info"
            color="grey darken-2"
            closable
            class="ma-0 cookie-alert"
            tile
          >
            <!--googleon: all-->
            <span data-nosnippet style="color: white">{{
              $t("paragraph.cookie_warning")
            }}</span>
            <router-link
              :to="'/' + $i18n.locale + '/legal-mentions'"
              style="color: white"
            >
              <span data-nosnippet>{{ $t("paragraph.cookie_link") }}</span>
            </router-link>
          </v-alert>
          <NuxtPage :page-key="$route.fullPath" />
          <TayoFooter />
        </v-layout>
      </v-main>
      <TayoBot v-if="!$vuetify.display.mobile" />
    </v-layout>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import TayoHeader from '../components/partials/TayoHeader'
import TayoFooter from '../components/partials/TayoFooter'
import TayoImmomig from '../pages/[locale]/TayoImmomig'

// Lazy loading
const TayoBot = defineAsyncComponent(() =>
  import('../components/partials/TayoBot.vue')
)

export default {
  components: {
    TayoHeader,
    TayoFooter,
    TayoBot,
    TayoImmomig
  },
  data () {
    return {
      showCookieAlert: false,
      displayExternalPage: false
    }
  },
  watch: {
    showCookieAlert: function (val) {
      if (!val) {
        this.setCookie('consent', new Date().toISOString().slice(0, 10), 365)
      }
    }
  },
  mounted () {
    if (this.getCookie('consent') === undefined) {
      this.showCookieAlert = true
    }
    const url = window.location.href
    if (url.includes('tayo-immomig')) {
      this.displayExternalPage = true
    }
  },
  methods: {
    setCookie (cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      const expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    },
    getCookie (name) {
      const value = '; ' + document.cookie
      const parts = value.split('; ' + name + '=')
      if (parts.length === 2) { return parts.pop().split(';').shift() }
    }
  }
}
</script>
<style>
    .v-main{
        min-height: 100vh;
    }
  body{
    width: 100%;
  }
  body > div {
    width: 100%;
  }
  body > div > div.v-layout{
    width: 100%;
  }
  .cookie-alert{
    z-index:1001;
    width: 100%;
  }
</style>
