<template>
  <div class="helperWrapper">
    <v-slide-x-transition>
      <v-card v-if="windowDisplayed" class="support-window pa-4">
        <v-card-title>
          <span style="width:36px;display: inline-block;">
            <v-slide-x-transition>
              <v-icon v-show="selectedReply !== ''" :icon="mdiChevronLeft" class="cursor-pointer icon-back" @click="selectReply('')" />
            </v-slide-x-transition>
          </span>
          <v-avatar class="mr-3">
            <v-img
              src="/img/tayonaute_yellow_circle.png"
              alt="TayoNaute - bot"
            />
          </v-avatar>
          {{ $t('tayobot.title') }}
        </v-card-title>
        <v-card-subtitle
          class="pt-2"
        >
          {{ $t('tayobot.introduction') }}
        </v-card-subtitle>
        <v-card-text class="content-wrapper">
          <v-fade-transition leave-absolute>
            <div v-show="selectedReply === ''" class="quick-reply-group">
              <v-row>
                <v-col cols="12">
                  <v-chip variant="outlined" @click="selectReply('meeting')">
                    {{ $t('tayobot.book_meeting') }}
                  </v-chip><br>
                  <v-chip variant="outlined" @click="selectReply('question')">
                    {{ $t('tayobot.ask_question') }}
                  </v-chip><br>
                  <v-chip variant="outlined" @click="selectReply('tayoMag')">
                    {{ $t('tayobot.alert') }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row align-content="end" justify="center">
                <v-col cols="3" class="ml-11">
                  <div class="snippet" data-title=".dot-elastic">
                    <div class="stage">
                      <div class="dot-elastic" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-fade-transition>
          <div class="quick-reply-selected">
            <v-fade-transition leave-absolute>
              <div v-if="selectedReply === 'question'" class="helper-content">
                <v-card-text class="pa-0 mt-3">
                  <v-select
                    v-if="topicsReady"
                    v-model="selectedItem"
                    placeholder="Have a question ? "
                    :items="topics"
                    item-title="title"
                    item-value="id"
                    variant="outlined"
                    :disabled="!selectEnabled"
                    @input="loadTopic"
                  />
                </v-card-text>
                <div v-if="topicContent !== ''" class="pb-2 mb-2">
                  <v-card-text v-if="topicContent !== ''" class="topicContent pa-0" v-html="topicContent" />
                </div>
                <div v-if="topicContent === '' && suggestions.length" class="suggestionDiv pb-2">
                  <v-card-subtitle class="pl-0 pb-2">
                    {{ $t('tayobot.suggestions') }}
                  </v-card-subtitle>
                  <v-card-text class="pa-0 ">
                    <v-chip v-for="suggestion in suggestions" :key="suggestion.id" class="mr-2 mb-2" @click="openSuggestion(suggestion.id)">
                      {{ suggestion.title }}
                    </v-chip>
                  </v-card-text>
                </div>
                <!--                <div class="no-response pt-3" v-html="$t('tayobot.answer_not_found', { email: 'hello@tayo-software.com' } )" />-->
              </div>
            </v-fade-transition>
            <v-fade-transition leave-absolute>
              <div v-if="selectedReply === 'tayoMag'" class="helper-content justify-space-between">
                <p>
                  {{ $t('tayobot.tayo_mag_sentence') }} <nuxt-link :to="'/' + $i18n.locale + '/tayo-mag/archives'">
                    Tayo Mag
                  </nuxt-link>
                  {{ $t('tayobot.newsletter') }}
                </p>
                <NewsletterForm />
              </div>
            </v-fade-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <v-btn
      aria-label="Open Tayo Help Center"
      class="support-button toggleUpDown"
      :class="{ 'rotate' : windowDisplayed }"
      :color="windowDisplayed ? '#FFCC00' : 'black'"
      :icon="windowDisplayed ? mdiWindowClose : mdiHelp"
      size="x-large"
      elevation="2"
      @click="toggleWindow"
    />
  </div>
</template>
<script>
import {
  mdiChevronLeft, mdiWindowClose,
  mdiHelp
} from '@mdi/js'

import showdown from 'showdown'
import NewsletterForm from '../cta/NewsletterForm'
export default {
  components: {
    NewsletterForm
  },
  data () {
    return {
      selectedReply: '',
      mdiChevronLeft,
      mdiWindowClose,
      mdiHelp,
      topics: [
      ],
      suggestions: [],
      topicContent: '',
      selectedItem: '',
      currentLanguage: 'en',
      windowDisplayed: false,
      selectEnabled: true,
      topicsReady: false
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) { this.loadTopic() }
    },
    '$i18n.locale': function () {
      this.dealWithTopics()
    }
  },
  mounted () {
    this.currentLanguage = this.$i18n.locale
    this.converter = new showdown.Converter()
  },
  methods: {
    openSuggestion (id) {
      this.selectedItem = id
      this.loadTopic()
    },
    dealWithTopics (callback) {
      // this.selectedItem = ''
      this.topicsReady = false
      this.suggestions = []
      this.getTopics().then((response) => {
        const topicsArray = response
        // @TODO : Add suggestion according to user journey
        // @If not possible, take 3 random suggestions
        if (response && response.length) {
          this.selectEnabled = true
          this.suggestions = response.slice(0, 3)
          topicsArray.unshift(
            {
              id: 1,
              title: this.$t('tayobot.select_item'),
              content: '',
              uri: ''
            }
          )
        } else {
          topicsArray.push(
            {
              id: -1,
              title: this.$t('tayobot.no_content'),
              content: '',
              uri: ''
            }
          )
          this.selectEnabled = false
        }
        this.topics = topicsArray
        this.selectedItem = this.topics[0].id
        this.topicsReady = true
        if (callback) {
          callback()
        }
      })
        .catch((error) => {
          this.error = error
          if (callback) {
            callback()
          }
        })
    },
    selectReply (reply) {
      if (reply === 'meeting') {
        this.$router.push('/' + this.$i18n.locale + '/request-demo')
      } else {
        this.selectedReply = reply
      }
    },
    getTopics () {
      // @TODO : Implements topics and suggestions in Strapi
      return $fetch('/api/faq?lg=' + this.$i18n.locale)
    },
    loadTopic () {
      const item = this.topics.find(x => x.id === this.selectedItem)
      if (item && item.content !== '') {
        this.topicContent = this.converter.makeHtml(
          item.content
        )
      } else {
        this.topicContent = ''
      }
    },
    toggleWindow () {
      // this.selectedItem = ''
      this.selectedReply = ''
      if (!this.topics.length) {
        this.dealWithTopics(() => {
          this.windowDisplayed = !this.windowDisplayed
        })
      } else {
        this.windowDisplayed = !this.windowDisplayed
      }
    }
  }
}
</script>
<style lang="scss">
  .icon-back{
    color:rgba(0,0,0,0.5);
  }
  .helperWrapper {
    position: fixed;
    bottom: 18px;
    right: 10px;
    z-index: 900;
    color: black;
    a{
      color: rgba(0, 0, 0, 0.5);
    }

    .helper-content{

      .v-field__input{
        height: 42px;
      }
    }

    .tayoForm {
      .newsletter-input {
        .v-field__field {
          height: 50px;
        }

        .v-label {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.5);
          @media (max-width: 959px) {
            font-size: unset;
          }
        }
      }
    }

    .meeting-link{
      text-align: center;
      p{
        margin-bottom: 12px;
      }
      a{
        font-size:22px;
        line-height: 36px;
        margin: 8px 0;
      }

    }

    .content-wrapper{
      height:80%;
    }
    .quick-reply-selected{
      height:100%;

    }
    .helper-content{
      transform-origin: center top 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
    }

    .v-avatar{
      background: black;
    }
    .v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot{
      min-height:0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      height: 46px;
    }
    .v-select__selection{
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      text-align: left;
    }
    .v-select .v-field{
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      height: 46px;
      min-height:0;
      .v-field__append-inner{
        padding-top:9px;
      }
    }
  a {

  }
  .topicContent{
    height: 220px;
    overflow-y: scroll;
    &.v-card-text{
      padding-top:0;
      margin-top:0;
    }
  }
  .v-chip{
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin:5px 3px;
    background: white;
  }
  .v-card-title{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color:black;
    padding-left:0;
  }
    .v-card-subtitle{
      font-style: italic;
    }
  .support-window{
    width: 360px;
    height: 460px;
    max-height: 90vh;
    z-index:500;
    background: #FFFFFF;
    box-shadow: -4px 1px 27px -9px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    right: 18px;
  }
    .support-button{
      position: fixed;
      bottom: 18px;
      right: 10px;
      z-index: 900;
      cursor: pointer;
    }

    .toggleUpDown {
      transition: transform .3s ease-in-out !important;
    }

    .toggleUpDown.rotate {
      transform: rotate(180deg);
    }

    .quick-reply-group{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }

    .dot-elastic {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.5);
      color: rgba(0,0,0,0.5);
      animation: dotElastic 1s infinite linear;
    }

    .dot-elastic::before, .dot-elastic::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot-elastic::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.5);
      color: rgba(0,0,0,0.5);
      animation: dotElasticBefore 1s infinite linear;
    }

    .dot-elastic::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.5);
      color: rgba(0,0,0,0.5);
      animation: dotElasticAfter 1s infinite linear;
    }

    @keyframes dotElasticBefore {
      0% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(1, 1.5);
      }
      50% {
        transform: scale(1, 0.67);
      }
      75% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    @keyframes dotElastic {
      0% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1, 1.5);
      }
      75% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    @keyframes dotElasticAfter {
      0% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1, 0.67);
      }
      75% {
        transform: scale(1, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }

  }

</style>
