<template>
  <v-layout wrap class="d-flex flex-column" :style="heightFooter">
    <v-footer theme="dark" flat color="#fff" class="tayoFooter">
      <v-container>
        <v-row v-if="footerIsReady" justify="center" class="pt-0 pt-sm-12">
          <v-col v-if="!smallHeight" cols="12" lg="5" md="8">
            <p id="anchorNewsletter" class="footer-signup">
              {{ $t("footer.newsletter_signup") }}:
            </p>
            <NewsletterForm />
            <tayo-swiss-labels class="pl-4 mt-8 mb-8 hidden-lg-and-up" />
            <v-row class="pl-4 mt-4 mb-8 hidden-lg-and-up">
              <nuxt-img
                src="https://www.nqa.com/medialibraries/NQA/NQA-Media-Library/CertificationLogos/Logos%20(with%20Accreditation)/NQA-ISO-27001-Logo-UKAS.jpg"
                alt="nqa-iso27001-ukas"
                height="100"
                width="auto"
                placeholder
              />
            </v-row>
          </v-col>
          <v-col
            v-if="!smallHeight"
            class="hidden-md-and-down"
            cols="0"
            lg="7"
            md="4"
          />
          <v-col cols="12" md="6" class="hidden-md-and-down">
            <div v-if="smallHeight">
              <p class="footer-signup">
                {{ $t("footer.newsletter_signup") }}:
              </p>
              <NewsletterForm />
            </div>
            <v-row>
              <nuxt-link :to="'/' + $i18n.locale">
                <nuxt-img
                  class="cursor-pointer"
                  src="/img/logo/TAYO-white.svg"
                  :alt="$t('alt.logo')"
                  height="54"
                  width="135"
                  placeholder
                />
              </nuxt-link>
            </v-row>
            <tayo-swiss-labels class="pl-4 mt-8" />
            <v-row class="pl-4 mt-4">
              <nuxt-img
                src="https://www.nqa.com/medialibraries/NQA/NQA-Media-Library/CertificationLogos/Logos%20(with%20Accreditation)/NQA-ISO-27001-Logo-UKAS.jpg"
                alt="nqa-iso27001-ukas"
                height="100"
                width="auto"
                placeholder
              />
            </v-row>
          </v-col>
          <v-col cols="6" md="3">
            <div class="mb-2 footer-header">
              {{ $t("footer.about") }}:
            </div>
            <div>
              <v-row
                justify="start"
                no-gutters
                class="d-flex flex-column footer-link"
              >
                <v-btn
                  v-for="link in links"
                  :key="link"
                  :to="injectPath(link)"
                  :aria-label="link.label"
                  color="black"
                  flat
                  class="pt-0 mb-2"
                >
                  {{ $t(link.label) }}
                </v-btn>
              </v-row>
            </div>
          </v-col>
          <v-col cols="6" md="3">
            <div class="mb-2 footer-header">
              {{ $t("footer.connect") }}:
            </div>
            <div>
              <div>
                <v-row
                  justify="start"
                  no-gutters
                  class="d-flex flex-column footer-link"
                >
                  <div class="address mb-4 mt-2" v-html="footerAddress" />
                  <v-btn
                    v-for="link in social"
                    :key="link.label"
                    color="black"
                    target="_blank"
                    :href="link.url"
                    :aria-label="link.label"
                    class="pt-0 mt-1"
                  >
                    {{ link.label }}
                  </v-btn>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- -->
        <v-row justify="center" class="pt-6 pb-md-0 pb-2 pb-md-8 align-center">
          <v-col
            cols="12"
            md="6"
            class="d-flex child-flex pb-md-0 pb-10 btn-wrapper"
          >
            <TayoButton button-type="demo" class="mr-md-2 mb-md-0 mb-4" />
            <community-button />
          </v-col>
          <v-col cols="6" md="3" class="pl-0">
            <div class="footer-privacy">
              <nuxt-link :to="injectLocaleFooter('/legal-mentions')">
                {{ $t("footer.terms_privacy") }}
              </nuxt-link>
            </div>
          </v-col>
          <v-col cols="6" md="3" class="pl-0">
            <div class="footer-copyright">
              © 2022 TAYO
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-layout>
</template>
<script>
import CommunityButton from '../cta/CommunityButton'
import NewsletterForm from '../cta/NewsletterForm'
import TayoSwissLabels from '../TayoSwissLabels'

export default {
  components: { CommunityButton, NewsletterForm, TayoSwissLabels },
  data: () => ({
    links: [
      {
        label: 'menu.solutions',
        url: '/solutions',
        fr: '/solution',
        de: '/losung'
      },
      { label: 'menu.pricing', url: '/pricing', fr: '/prix', de: '/preis' },
      { label: 'menu.team', url: '/team', fr: '/equipe', de: '/team' },
      { label: 'menu.tayo-map', url: '/tayo-map' },
      { label: 'menu.blog', url: '/blog' },
      // the tayo mag page from hubspot has been removed
      // { label: 'menu.tayo-mag', url: '/tayo-mag/archives' },
      {
        label: 'menu.how_it_works',
        url: '/how-it-works',
        fr: '/comment-cela-fonctionne',
        de: '/wie-es-funktioniert'
      }
    ],
    social: [
      {
        label: 'LinkedIn',
        url: 'https://www.linkedin.com/company/11355520/admin/'
      },
      {
        label: 'Youtube',
        url: 'https://www.youtube.com/channel/UCfDszKZ3_4IVsH7hd9DhrUQ'
      },
      { label: 'Instagram', url: 'https://www.instagram.com/tayosoftware' }
    ],
    address:
      '<a href="https://g.page/TayoSoftware?share">EPFL Innovation Park, Bat. F<br>1020 Lausanne<br> Switzerland</a>',
    germanAddress:
      '<a href="https://goo.gl/maps/aqq4PmeewQYYzwtw6">c/o Haus der Immobilien<br>Zollikerstrasse 65<br>8702 Zollikon<br> Switzerland</a>',
    styleInput: {
      'font-family': "'MaisonNeue', sans-serif",
      'font-style': 'normal',
      'font-weight': 700,
      'font-size': '48px',
      'line-height': '64px',
      color: 'rgba(255, 255, 255, 0.2)'
    },
    valid: false,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    forceRefresh: 1,
    smallHeight: false,
    footerIsReady: false
  }),
  computed: {
    footerAddress () {
      return this.$i18n.locale === 'de' ? this.germanAddress : this.address
    },
    heightFooter () {
      return 'min-height: calc(100vh - 68px);'
    }
  },
  mounted () {
    this.smallHeight =
      typeof window !== 'undefined' &&
      window.innerHeight < 700 &&
      !this.$vuetify.display.mobile
    const vm = this
    window.addEventListener('resize', () => {
      vm.smallHeight =
        typeof window !== 'undefined' &&
        window.innerHeight < 700 &&
        !vm.$vuetify.display.mobile
    })
    this.footerIsReady = true
  },
  methods: {
    injectPath (item) {
      if (this.$i18n.locale === 'en') {
        return '/' + this.$i18n.locale + item.url
      }
      const path = item[this.$i18n.locale] ? item[this.$i18n.locale] : item.url
      return '/' + this.$i18n.locale + path
    },
    injectLocaleFooter (path) {
      return (
        '/' +
        (this.$i18n && this.$i18n.locale ? this.$i18n.locale + path : '' + path)
      )
    }
  }
}
</script>
<style lang="scss">
.tayoFooter {
  .v-btn__content {
    white-space: normal;
    width: 100%;
  }
  .address a {
    color: white;
    text-decoration: none;
    margin-bottom: 15px;
  }
  background: black;
  p {
    color: white;
  }
  > .v-container {
    max-width: 1248px;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .btn-wrapper {
    @media (max-width: 959px) {
      flex-direction: column;
    }
  }

  .footer-signup {
    font-family: "MaisonNeue", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    margin-bottom: 32px;
    @media (max-width: 959px) {
      margin-bottom: 6px;
    }
  }
  .footer-link {
    .v-btn--active {
      background-color: transparent;
      .v-btn__overlay,
      .v-btn__underlay {
        opacity: 0;
      }
    }
  }

  .footer-link .v-btn,
  .footer-link a {
    text-align: left;
    text-transform: capitalize;
    justify-content: start;
    padding: 0;
    font-size: 20px;
    line-height: 130%;
    color: white;
    @media (max-width: 959px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .footer-header {
    color: rgba(255, 255, 255, 0.7);
    font-family: "MaisonNeue", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    @media (max-height: 700px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .footer-copyright {
    @media (max-width: 959px) {
      text-align: right;
    }
  }
  .footer-copyright,
  .footer-privacy {
    font-family: "MaisonNeue", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.5);
    padding: 12px;
    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
